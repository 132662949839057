import React, { useState, useEffect } from 'react';
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.css";
import {
    Tabs,
} from "antd";
import styled from 'styled-components';
import SystemApp from './System/SystemApp';
import SystemHg from './System/SystemHg';
import SystemFq from './System/SystemFq';
const { TabPane } = Tabs;
const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
  /* display:flex;
  flex-wrap:wrap;
  flex-direction:column; */
`;

const TabCotainerStyle = styled.div`
    color : #fff;

  & .ant-tabs-nav{
      padding : 3px 0px 0px 0px !important;
  }
  
  & .ant-tabs-nav-list {
    width : 100%;
  }
  & .ant-tabs-tab {
    justify-content : center;
    width : 50% !important;
    margin : 0;
    /* font-size : 3vw !important; */
    line-height : 1.7 !important;
    text-align : center;
    padding : 5px 3px;
    font-weight : 700;
  }

  & .ant-tabs-top > .ant-tabs-nav::before{
    border-bottom : 1px solid #454545;
  }

  & .ant-tabs-ink-bar{
    background : #f31b44 !important;
    display : block !important;
  }
  & .ant-tabs-tab-btn{
    color: #fff;
    white-space : nowrap !important;
    word-break : keep-all;
  }
  & .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active{
    background-color: #111 !important;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color : #fff !important;
    font-weight : 700;
  }
  
  & .ant-tabs-nav-list{
    /* border-bottom : 8px solid #212121; */
    border-bottom : none !important;
  }
  
  & .ant-card{
    background-color : transparent !important;
    color : #fff;
    width : 95%;
    margin : 10px auto;
  }
  & .ant-card-head{
    color : #fff;
  }
  & .ant-card.super .ant-card-head{
    background-color : #f49f11;
  }
  & .ant-card.weekly .ant-card-head{
    background-color : #956bc1;
  }
  & .ant-card-body{
    /* padding : 5px !important; */
  }

  & .system {
    width : 80%;
    margin : 0 auto;
    padding-top : 11vw;
  }

  & .system .content-item{
    display : flex;
    color : #fff;
    justify-content : space-between;
    margin-bottom : 8vw;
  }

  & .system .content-item .right-content{
    width : 50%;
    margin-top : 12vw;
  }

  & .system .content-item .title{
    color : #15ff94;
    font-size : 4.5vw;
  }

  & .system .content-item p{
    font-size : 2.7vw;
    word-break : keep-all;
  }

  & .system .content-item h3{
    margin-bottom :0;
  }

  & .system .content-item h5{
    margin-bottom :-3px;
  }

  & .system .content-item .weekly{
    color : #be87c7;
  }

  & .system .content-item .super{
    color : #ff5ca8;
  }

  & .system .content-item .red{
    color : #ff6871;
  }

  & .system .content-item .green{
    color : #1ad57c;
  }

  & .system .content-item.absolute{
    position : relative;
  }

  & .system .content-item.absolute .right-content{
    width : 40vw;
    position : absolute;
    left : 50%;
    top : 17vw;
  }

  & .system.system-hg{
    padding-bottom : 50vw;
  }

  & .system.system-fq .content-item.absolute .right-content{
    top : 8vw;
  }

`

export default function (props) { 
    let cid = props.cid;

    return (
        <>
        <Container>
            <TabCotainerStyle>
            <Tabs defaultActiveKey="1">
                <TabPane tab="어플 이용 안내" key="1">
                  <SystemApp cid={cid}/>
                </TabPane>
                {/* <TabPane tab="헬스가이드 티칭 안내" key="2">
                  <SystemHg />
                </TabPane> */}
                {/* <TabPane tab="스마트 프리퀀시 안내" key="3">
                  <SystemFq />
                </TabPane> */}
            </Tabs>
            </TabCotainerStyle>
        </Container>
        </>
    );
}
import moment from 'moment';

export function getDay(date) {
    let day = moment(date).day();
    let txt = '';
    if(day === 0) { txt='일'; }
    if(day === 1) { txt='월'; }
    if(day === 2) { txt='화'; }
    if(day === 3) { txt='수'; }
    if(day === 4) { txt='목'; }
    if(day === 5) { txt='금'; }
    if(day === 6) { txt='토'; }
    return txt;
}
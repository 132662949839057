import React from 'react';
import "antd/dist/antd.css";
import styled from 'styled-components';
import SystemFqImage1 from '../../../images/system_fq_1.png';
import SystemFqImage2 from '../../../images/system_fq_2.png';
import SystemFqImage4 from '../../../images/system_fq_4.png';
import SystemFqImage5 from '../../../images/system_fq_5.png';
import SystemFqImage6 from '../../../images/system_fq_6.png';
const { innerWidth: width, innerHeight: height } = window;

const ScrollView = styled.div`
    height:${height - 150}px;
    overflow:auto;
    padding-bottom : 50px;
`

export default function () {
    return (
        <ScrollView>
        <div className="system system-fq">
            <div className="content-item" style={{marginBottom : '15vw'}}>
                <div className="left-image">
                    <img src={SystemFqImage1} alt="시스템 프리퀀시 이용 이미지" style={{width : '27.6vw'}}/>
                </div>
                <div className="right-content">
                    <h3 className="title">스마트 프리퀀시 제도</h3>
                    <p>
                    스타벅스 프리퀀시 제도처럼 헬스가이드 티칭을 받을 때마다 스탬프와 레벨포인트를 획득하여 레벨 및 출석보상을 받아 운동의 즐거움을 느낄 수 있는 제도 입니다.
                    </p>
                </div>
            </div>
            <div className="content-item absolute">
                <div className="left-image">
                    <img src={SystemFqImage2} alt="시스템 프리퀀시 이용 이미지" style={{width : '78.2vw', paddingBottom : '20vw'}}/>
                </div>
                <div className="right-content">
                    <p>
                    담당 트레이너와 헬스가이드티칭을 받은 후 평가요청에 별점주기를 진행하게 되면 레벨포인트와 출석스탬프가 쌓이게 됩니다.
                    </p>
                    <p className="red">
                    수업평가를 하지 않으면 출석 스탬프가 제공되지 않으니 수업 후 3시간 이내에 꼭 평가를 진행 바랍니다.
                    </p>
                    <p className="green">
                    평가 별점은 해당 트레이너가 볼 수 없는 부분임으로 평가에 알맞는 별점을 주시면 됩니다.
                    </p>
                </div>
            </div>
            <div className="content-item" style={{paddingBottom : '5vw'}}>
                <div className="left-image">
                    <img src={SystemFqImage4} alt="시스템 프리퀀시 이용 이미지" style={{width : '27.6vw'}}/>
                </div>
                <div className="right-content" style={{marginTop : '10vw'}}>
                    <p>
                    지금의 출석도달 단계와 나의 레벨단계가 어디인지 전반적인 프리퀀시의 정보를 앱에서 언제든지 한눈에 확인이 가능합니다. 
                    </p>
                </div>
            </div>
            <div className="content-item" >
                <div className="left-image">
                    <img src={SystemFqImage5} alt="시스템 프리퀀시 이용 이미지" style={{width : '36.5vw'}}/>
                </div>
                <div className="right-content" style={{marginTop : 0}}>
                    <p>
                    출석을 할때마다 해당 레벨포인트가 쌓이고 단계별로 레벨이 상승하게 됩니다.
                    </p>
                    <p className="green">
                    스마트 프리퀀시에서 제공되는 레벨은 아이언, 브론즈, 실버, 골드, 플레티넘, 다이아몬드, 마스터, 그랜드마스터, 첼린저, 레전드로 총 10단계 레벨이 구성되어 있습니다.
                    </p>
                </div>
            </div>
            <div className="content-item" >
                <div className="left-image">
                    <img src={SystemFqImage6} alt="시스템 프리퀀시 이용 이미지" style={{width : '36.5vw'}}/>
                </div>
                <div className="right-content" style={{marginTop : 0}}>
                    <p>
                    헬스가이드 티칭 평가를 하게되면 1개의 스탬프를 받게되고 점차 쌓여서 해당 목표에 도달하면 레벨 보상별 할인권과 보상 상품을 받을 수 있습니다.
                    </p>
                    <p>
                    이제 헬스가이드 티칭을 무료로 받고 프리퀀시 제도를 통해 운동의 재미와 다양한 보상 혜택을 모두 받으보세요.
                    </p>
                </div>
            </div>
        </div>
        </ScrollView>
    );
}
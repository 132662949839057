import React, { useState, useEffect } from 'react';
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.css";
import {
    Layout, Menu, Button, Tabs,
    Card, Modal, Calendar, Select, Radio, Col, Row, Typography, Divider, Input, Form
} from "antd";
import queryString from 'query-string';
import axios from 'axios';
import styled from 'styled-components';
import {
    ScheduleOutlined,
    LeftOutlined
} from '@ant-design/icons';
import { Table, Tag, Space } from 'antd';
import moment from 'moment';
import store from '../store';
import { getDay } from './getDay';
import { getMonday } from './getMonday';
import { getDayDifference } from './getDayDifference';
import { pr } from './pr';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
  display:flex;
  flex-wrap:wrap;
  flex-direction:column;
  color : #fff;

  & h2{
      color : #fff;
      text-align : center;
      padding : 12px 0;
      border-bottom : 2px solid #15ff94;
      font-size : 18px;
  }
`;

const HeaderStyle = styled.div`
  text-align:center;
  height:65px;
  line-height : 65px;
  border-bottom : 1px solid #f9f9f9;

  & .close-icon {
    position : absolute;
    top : 20px;
    left : 20px;
    font-size : 22px;
  }
`

const BodyContainer = styled.div`
  width : 90%;
  margin : 0 auto;
`

const ScrollView = styled.div`
  height:${height - 60}px;
  overflow:auto;
`

const CalendarStyle = styled(Calendar)`
    width:300px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
`;

const DateContainer = styled.div`
    padding-bottom:5px;
    width : 100%;
    overflow-x : scroll;
    margin-bottom : 20px;

    & .ant-table{
        background-color : #111;
    }

    & .ant-table-cell{
        background-color : #111;
        color : #fff;
    }

    & .ant-table-cell .ant-btn{
        background-color : #111;
        color : #fff;
    }

    & .ant-table-tbody > tr.ant-table-row:hover > td {
        background-color : #111;
    }

    & .ant-btn:hover, .ant-btn:focus {
        color: #15ff94;
        border-color: #15ff94;
    }

    & .ant-table-thead > tr > th{
        border : none;
        padding-bottom : 0;
    }

    & .ant-table-tbody > tr > td{
        border : none;
    }

    & .ant-table-tbody > tr > td{
        padding : 4px 7px;
    }
`;

const DateButtonStyle = styled(Button)`
    border-radius : 50%;
    font-size : 12px;
    width : 35px;
    height : 35px;
    padding : 0;
    text-align : center;
`
const TeacherContainer = styled.div`
    margin-top:30px;
`;

const TeacherTimeContainer = styled.div`
    margin-top:30px;
`;

const DateStyle = styled(Row)`
    padding-top:10px;
    padding-bottom:10px;
    padding-left:10px;
    font-weight:bold;
`
const TrainerContainer = styled.div`
    padding-left:10px;
    & .name-txt {
        padding-top:15px;
        font-size:14px;        
    }    
    & .tname {
        padding-top:10px;
        font-weight : 700;
        margin-bottom : 15px;
    }
    & .tmessage {
        padding-top:5px;
        font-size : 12px;
        color : #999;
    }   
    & .btn {
        margin-left:5px;
    }     
    & .reservation-btn {
        margin-right:5px;
        width : 23%;
        max-width:65px;
        margin-bottom:10px;
        font-size : 12px;
        padding: 0;
        text-align : center;
        background-color : #333; 
        border : none;
        color : #fff;
    }        
    & .time-container{
        /* justify-content : space-between; */
    }
`

const Memo = styled(Input)`
    width:200px;
`;

const Label = styled.span`
    display:inline-block;
    width:50px;
`;

export default function () {

    const [category,setCategory] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [showTrainer, setShowTrainer] = useState(false);
    const [showDateMessage, setShowDateMessage] = useState(true);
    const [login, setLogin] = useState({
        mcd: '',
        gubun: '',
        cid: '',
    });
    const [time, setTime] = useState([]);
    const { confirm,success,error } = Modal;
    const [cTitle, setCTitle] = useState('');
    const [reservation, setReservation] = useState({
        coupon_seq: 0,
        tcd: 0,
        class_hour_seq: 0,
        class_date: '',        
    });
    let mCategory = '';
    let mMemo = '';

    function handleCategory(value){
       mCategory = value;
    }

    function handleMemo(e){        
       mMemo = e.target.value;
    }

    function showConfirm(){

        confirm({
            title: '예약 하시겠습니까?',
            icon: <ScheduleOutlined />,
            content: 
            <>
            <div>{cTitle[0]}</div><div>{cTitle[1]}</div><div>{cTitle[2]}</div>
            <div>
                <Label>분류</Label>
                <Select onChange={handleCategory} placeholder="분류선택(필수)" style={{marginLeft:4}}>
                {
                    category.map((item, index)=> {
                        return(
                            <Select.Option value={item} key={index}>{item}</Select.Option>
                        )
                    })
                }
                </Select>
                </div>
                <Label>메모</Label> <Memo onChange={handleMemo} placeholder="(선택)"></Memo>
            </>
            ,
            okText: '확인',
            cancelText: '취소',
            onOk() {                
                return new Promise((resolve,reject)=>{
                    if(mCategory==='') {
                        error({
                            content: '분류를 선택하세요.'
                        })
                        reject();
                    }                    
                    else {                        
                        on_reservation();                      
                        resolve();                        
                    }
                })
            },
            onCancel() {
                setCTitle('');
            },
        });
    }

    function showSuccess(msg) {
        success({
            content:msg,
            onOk() {
                window.location.href='/hg_home';
            }
        })
    }

    function showError(msg) {
        error({
            content:msg
        })
    }

    let mcd = '';
    let gubun = '';
    let cid = '';
    let token = '';
    let result;
    let api_host = store.production.api_host;
    if( process.env.REACT_APP_ENV === 'dev' ) api_host = store.development.api_host;
    if( process.env.REACT_APP_ENV === 'staging' ) api_host = store.staging.api_host;
    const today = moment().format('YYYY-MM-DD');

    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [seldate, setSelDate] = useState('');

    useEffect(() => {
        (async function () {

            //Login
            const params = queryString.parse(window.location.search);
            token = localStorage.getItem('token');
            cid = localStorage.getItem('cid');
            if (params.token !== undefined || params.cid !== undefined) {
                token = params.token;
                cid = params.cid;
            }
            if (token) {
                result = await axios.post(api_host + '/sp/get_login', {
                    token: token,
                    cid : cid
                });
                if (result.data.mcd !== undefined) { mcd = result.data.mcd; }
                if (result.data.gubun !== undefined) { gubun = result.data.gubun; }
                if (mcd !== '') {
                    setLogin({
                        mcd: mcd,
                        cid: cid,
                        gubun: gubun
                    });
                    localStorage.setItem('token', token); // Save token
                    localStorage.setItem('cid', cid);
                }
            }

            // 날짜 목록      
            let days = [];
            const { edate, sdate, seq } = params;
            const dayDifference = getDayDifference(sdate, edate);
            let tdate = sdate;
            {
                [...Array(dayDifference + 1)].map((n, i) => {
                    days.push(tdate);
                    tdate = moment(tdate).add(1, 'day').format('YYYY-MM-DD');
                    return null;
                })
            }
            create_date_button(sdate, edate);
            setReservation(
                state => {
                    return { ...state, coupon_seq: seq }
                }
            );

            // 카테고리
            result = await axios.post(api_host + '/hg/get_category', {});
            setCategory(result.data);
        })();
    }, []);

    const close_webview_window = () => {
        if (window.ReactNativeWebView !== undefined) {
            window.ReactNativeWebView.postMessage('close_webview_window');
        }
    }

    const close_click = () => {
        if (window.ReactNativeWebView === undefined) {
            alert('앱용 기능입니다.');
            return;
        }
        close_webview_window();
    }

    const back_click = () => {
        window.location.href = "/hg_home";
    }

    const reservation_click = () => {
        alert('예약완료');
        window.location.href = 'hg_home';
    }

    const on_date_click = async (date) => {
        setShowDateMessage(false);
        setShowTrainer(true);

        // 날짜선택
        setReservation(
            state => {
                return { ...state, class_date: date }
            }
        );

        // 근무시간표
        result = await axios.post(api_host + '/hg/get_teacher_time', {
            cid: cid,
            tdate: date
        });
        setTime(result.data.rows);
    }

    const create_date_button = (sdate, edate) => {

        const dayDifference = getDayDifference(sdate, edate);
        let tdate = sdate;
        let rows = [];
        let title = '';

        // Columns
        [...Array(dayDifference + 1)].map((n, i) => {
            title = getDay(tdate);
            rows.push({
                title: title,
                dataIndex: 'x' + i,
                key: 'x' + i,
                align: "center",
                render: title => (
                    <>
                        <DateButtonStyle onClick={() => on_date_click(title)}>{moment(title).format("M/D")}</DateButtonStyle>
                    </>
                )
            });
            tdate = moment(tdate).add(1, 'day').format('YYYY-MM-DD');
        });
        setColumns(rows);

        // DataSource
        tdate = sdate;
        let item = {};
        [...Array(dayDifference + 1)].map((n, i) => {
            item['x' + i] = tdate
            tdate = moment(tdate).add(1, 'day').format('YYYY-MM-DD');
        });
        setDataSource([item]);
        setLoaded(true);
    }

    const on_reservation_click = (str, seq, tcd) => {
        setCTitle(str);
        setReservation(
            state => {
                return { ...state, class_hour_seq: seq, tcd: tcd }
            }
        );
    }

    useEffect(() => {
        if (cTitle !== '') {
            showConfirm();
        }
    }, [cTitle]);

    const on_reservation = async () => {
        setCTitle('');

        let result;
        const data = {
            class_date: reservation.class_date,
            class_hour_seq: reservation.class_hour_seq.toString(),
            coupon_seq: reservation.coupon_seq,
            tcd: reservation.tcd,
            cid: login.cid,
            mcd: login.mcd,
            category : mCategory,
            memo : mMemo
        }
        result = await axios.post(api_host + '/hg/reservation', data);
        if (result.data.ret === 'Y') {
            showSuccess('예약되었습니다.');   
        }
        else {
            showError(result.data.msg);
        }
    }

    return (
        <Container>
            <HeaderStyle>
            <LeftOutlined onClick={() => back_click()} className="close-icon"/>
            예약하기
          </HeaderStyle>
          <BodyContainer>
            {loaded ?
                <>
                <DateContainer>
                    <Row>
                        <Col>
                            <Table columns={columns}
                                dataSource={dataSource}
                                pagination={false}
                                rowKey="x0"
                            ></Table>
                        </Col>
                    </Row>
                </DateContainer>
                {showDateMessage ?
                <Row>
                    <Col className="date-text">
                        원하는 날짜를 선택하세요.
                    </Col>
                </Row>
                : null}
                </> : null}

            { showTrainer ?
                <TrainerContainer>
                    <div>
                        {reservation.class_date}
                    </div>
                    <Row>
                        <Col className="tmessage">예약 시간을 선택하세요.</Col>
                    </Row>
                    <>
                        {time.map((n, i) => {
                            return (
                                <div key={i}>
                                <Row className="tname"><Col>{n.tname + " 트레이너"}</Col>
                                </Row>
                                <Row className="time-container">
                                    {n.time.map((n1, i1) => {
                                        let msg = [
                                            n.tname + " 트레이너",
                                            reservation.class_date,
                                            n1.stime + n1.ampm + '~' + n1.etime + n1.ampm
                                        ]
                                        // return (<Button className="reservation-btn" onClick={() => on_reservation_click('<div>강사</div> : '+ n.tname + ' 트레이너' +  '  일시 : ' + reservation.class_date +  " \n시간 : " + n1.stime + n1.ampm + '~' + n1.etime + n1.ampm, n1.seq, n.tcd)}>{n1.stime}{n1.ampm}</Button>)
                                        return (<Button key={i1} className="reservation-btn" onClick={() => on_reservation_click(msg, n1.seq, n.tcd)}>{n1.stime}{n1.ampm}</Button>)
                                    })}
                                </Row>
                                </div>
                            );
                        })}

                        
                    </>
                </TrainerContainer>
                : null}
                </BodyContainer>
        </Container>);
}


    // mcd: {login.mcd}<br/>
    // cid : {login.cid} <br/>
    // coupon_seq:{reservation.coupon_seq}<br/>                    
    // tcd:{reservation.tcd}<br/>
    // class_hour_seq:{reservation.class_hour_seq}<br/>
    // class_date:{reservation.class_date}<br/>

/*

{time.map((n, i) => {
                        return (
                            <Row key={i} className="tname">
                                <Col>
                                    <div className="name-txt">
                                        {n.name}
                                    </div>
                                    <Row>
                                        <Col>
                                            {n.time.map((n2, i2) => {
                                                return (<Button className="btn" type="round" key={i2}>{n2.time}</Button>);
                                            })}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>);
                    })}

                    */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    LeftOutlined,
    CloseOutlined
} from '@ant-design/icons';

const Container = styled.div`
    background-color : #faf9d0;
    position : relative;
    padding-right : 20px;
    padding-top : 20px;
    padding-bottom : 30px;
`;

const HeaderStyle = styled.div`
  text-align:center;
  /* height:20px; */
  color : black;


  & .close-icon {
    position : absolute;
    top : 20px;
    left : 20px;
    font-size : 22px;
  }
`

const ContentContainer = styled.div`
  background-color : #faf9d0;

  & h1{
      text-align : center;
      color : #d04855;
      font-size : 20px;
      font-weight : 800;
  }

  & h3{
      color : #d04855;
      font-weight : 600;
  }

  & h4{
      font-weight : 600;
  }

  & p{
      font-size : 12px;
  }

  & ul {
      list-style : square;
  }

  & ol{
      padding-left : 10px;
      font-size : 12px;
      margin-bottom : 20px;
  }

`

export default function () {
    const moveBack = () => {
        window.history.back();
    }
    
    return (
        <Container>
            <HeaderStyle>
                <CloseOutlined className="close-icon" onClick={() => moveBack()}/>
            </HeaderStyle>
            <ContentContainer>
                <h1>프리퀀시 안내</h1>
                <ul>
                    <li>
                        <h3>프리퀀시 란?</h3>
                        <p>일주일 한번 PT를 받고 목표 스탬프를 완료하면 할인권과 상품을 받을 수 있고 해당 목표치마다 각각의 레벨을 부여받으면서 운동의 즐거움과 목표 레벨을 달성하는 성취감까지 느낄 수 있는 시스템 제도입니다.</p>
                    </li>
                    <li>
                        <h3>프리퀀시 맴버쉽 등급 제도</h3>
                        <p>VIP1 : 헬스 1개월 등록한 회원</p>
                        <p>VIP3 : 헬스 3개월 등록한 회원</p>
                        <p>VIP6 : 헬스 6개월 등록한 회원</p>
                    </li>
                    <li>
                        <h3>맴버쉽 쿠폰 안내</h3>
                        <h4>슈퍼 쿠폰</h4>
                        <p>24시간 전 무료 1:1헬스가이드티칭 수업을 언제든지 예약 가능한 쿠폰입니다.</p>
                        <p>본 쿠폰은 헬스 등록시 전 맴버쉽 등급회원 (VIP1, VIP3, VIP6)에게 무료로 3장을 지급합니다.</p>
                        <h4>위클리 쿠폰</h4>
                        <p>일주일 전(토요일 자정 전)까지 다음주 무료 1:1 헬스가이드티칭 수업을 예약가능한 쿠폰입니다.</p>
                        <p>본 쿠폰은 VIP1, VIP3 맴버에게 매주 1장씩 무료로 지급합니다. (일주일에 한장씩 일요일 자정에 어플로 자동 지급)</p>
                        <p>VIP6 회원은 이벤트 행사 시 기원자에 한 해 무료로 지급합니다. (지원은 트레이너에게 직접 지원)</p>
                    </li>
                    <li>
                        <h3>프리퀀시 스탬프 획득 방법 안내</h3>
                        <ol>
                            <li>스마트짐 앱을 통해 1주일 전 1:1 헬스가이드티칭 사전예약을 신청합니다.</li>
                            <li>예약한 시간에 담당트레이너와 함께 1:1 헬스가이드티칭을 받습니다.</li>
                            <li>티칭 후 운동결과에 대한 평가를 진행하고 프리퀀시 스탬프를 지급 받습니다.</li>
                            <li>프리퀀시 목표에 달성 시 프리퀀시 레벨과 상품을 지급 받습니다.</li>
                        </ol>
                    </li>
                    <li>
                        <h3>프리퀀시 이용 주의사항</h3>
                        <ol>
                            <li>1:1 헬스가이드티칭 예약 변경 시 하루 전 주1회에 한해서만 변경이 가능합니다. (담당트레이너에게 요청)</li>
                            <li>평가를 하지 않는 경우 (수업 종료 후 3시간 이내) 프리퀀시 스탬프를 지급 받지 못합니다.</li>
                        </ol>
                    </li>
                    
                </ul>
            </ContentContainer>
        </Container>);
}
const store = {
    development: {
        api_host: 'http://192.168.76.80:3000'
    },
    staging: {
        api_host: 'http://192.168.76.23:3000'
    },
    production: {
        api_host: 'https://api.smartg.kr:3000'
    },        
    api_host: 'https://api.smartg.kr:3000'
    // api_host: 'http://192.168.76.80:3000' 
}

module.exports = store;
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import store from '../store';
import {
    Layout, Menu, Button, Tabs,
    Card,
    Spin,
    Row,
    Col,
    Modal,
    Rate
} from "antd";
import "antd/dist/antd.css";
import styled from 'styled-components';
import axios from 'axios';
import { pr } from './pr';
import { getDay } from './getDay';
import {
    CloseSquareOutlined, DownloadOutlined,
    LeftCircleOutlined, RightCircleOutlined,
    ExclamationCircleOutlined,
    SketchOutlined
} from '@ant-design/icons';
import icon_star from '../images/icon_star.png'
import icon_reservation from '../images/icon_reservation.png'
import icon_class from '../images/icon_class.png'

const { confirm, success, error } = Modal;

const Container = styled.div`
    width : 90%;
    margin : 0 auto;

`

const CardStyle = styled.div`
    color : #fff;
    background-color : #222222;
    border-radius : 10px;
    padding : 25px 20px;
    border : 2px solid #535353;
    margin-bottom : 10px;
    position : relative;

    & .state{
        font-size : 16px;
        margin-bottom : 10px;
    }

    & .state img{
        margin-right : 4px;
        width : 20px;
    }

    & .yellow{
        color : #fff566;
    }

    & .orange{
        color : #fa8c16;
    }

    & p{
        margin-bottom : 0;
        font-size : 13px;
    }
`
const SpinContainer = styled.div`
    display:flex;
    justify-content:center;
    padding-top:10vh;    
`;

const ButtonStyle = styled.button`
    padding : 3px 12px;
    font-size : 11px;
    border : none;
    border-radius : 5px;
    position : absolute;
    right : 5%;
    top : 50%;
    margin-top : -25px;


    &.rate{
        background-color : #ff4b50;
    }

    &.req{
        background-color : #4c6eec;
    }
    & img{
        display : block;
        margin : 0 auto;
        padding-top : 3px;
        width : 20px;
    }
`

const ModalContainer = styled(Modal)`

`

export default function (props) {

    const [loaded, setLoaded] = useState(false);
    const [rows, setRows] = useState([]);
    const [login, setLogin] = useState([]);
    const [show, setShow] = useState(false);
    let api_host = store.production.api_host;
    if( process.env.REACT_APP_ENV === 'dev' ) api_host = store.development.api_host;
    if( process.env.REACT_APP_ENV === 'staging' ) api_host = store.staging.api_host;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [comment, setComment] = useState('');
    const [ratingData, setRatingData] = useState({
        tcd: '',
        mcd: '',
        category: '',
        rating: '',
        cid: '',
        res_seq: '',
        class_date:'',
    });

    useEffect(() => {
        (async function () {
            const { mcd, cid, gubun } = props.login;
            let result;

            try {
                result = await axios.post(api_host + '/hg/get_reservation', {
                    cid: cid,
                    mcd: mcd,
                    gubun: gubun
                });
            } catch (e) {
                alert(e);
            }
            setRows(result.data.rows);
            setLogin(props.login);
            setLoaded(true);

        })();
    }, []);

    useEffect(() => {
        if (loaded && login.mcd !== undefined) {
            setShow(true);
        }
    }, [login, loaded]);

    const set_rate_state = async (res_seq, state) => {
        const result = await axios.post(api_host + '/hg/set_rate_state', {
            res_seq: res_seq,
            state: state
        });
        if (result.data.ret === 'Y') {
            success({
                content: '별점요청이 완료되었습니다.',
                onOk() {
                    window.location.href = '/hg_home';
                }
            })
        }
        else {
            error({
                content: result.data.msg
            })
        }
    }

    const on_rate_request_pop = (seq) => {
        confirm({
            title: '별점요청을 하시겠습니까?',
            icon: <ExclamationCircleOutlined />,
            content: "회원 출석이 인정됩니다.",
            okText: '확인',
            cancelText: '취소',
            onOk() {
                set_rate_state(seq, '요청');
            },
            onCancel() {
            },
        });
    }

    const on_rate_change = value => {
        setRatingData((state) => {
            return { ...state, rating: value.toString() }
        });
    }

    const on_rating_pop = async (res_seq, tcd, class_date) => {

        setRatingData((state) => {
            return {
                ...state,
                cid: login.cid,
                mcd: login.mcd,
                tcd: tcd,
                res_seq: res_seq,
                class_date: class_date
            }
        });

        let result;
        result = await axios.post(api_host + '/hg/get_random_comment');
        setComment(result.data.comment);
        setIsModalVisible(true);

        setRatingData((state) => {
            return {
                ...state,
                cid: login.cid,
                mcd: login.mcd,
                tcd: tcd,
                res_seq: res_seq,
                category: result.data.category
            }
        });
    }

    const on_rate_submit = async () => {
        if (ratingData.rating === '') {
            error({
                content: '별점을 선택하세요.'
            });
            return;
        }

        // 프리퀀시 스탬프 +1        
        await axios.post(api_host + '/fq/add_stamp',{
            cid:ratingData.cid,
            mcd:ratingData.mcd,
            res_seq:ratingData.res_seq,
            class_date:ratingData.class_date
        });

        // 프리퀀시 적립금 +10
        await axios.post(api_host + '/fq/add_point',{
            cid:ratingData.cid,
            mcd:ratingData.mcd,
            point:10
        });        

        // 별점등록
        await axios.post(api_host + '/hg/rate', ratingData);
        success({
            content: '별점 등록이 완료되었습니다.',
            onOk() {
                window.location.href = '/hg_home?isFrequency=Y&isRate=Y';
            }
        });
    }

    const now = moment().format('YYYY-MM-DD HH:mm');
    let state;
    let date;
    let day;
    let class_end; // 수업종료시간
    let star_point_end; // 별점처리 제한시간
    let res_seq;
    let rate_state;
    let show_req_star; // 별점요청    
    let show_rate_star; // 별점주기  
    let final_state;  
    let icon;
    let tcd;
    let color;

    const desc = ['1', '2', '3', '4', '5'];
    return (
        <Container>
            <ModalContainer title="별점주기"
                onOk={on_rate_submit}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
            >
                <div>{comment}</div>
                <Rate tooltips={desc} onChange={on_rate_change} />
            </ModalContainer>
            {show ?
                <>
                    {rows.length === 0 ?
                        <CardStyle title="NO RESERVATION" extra={<a href="#"></a>}>
                            <p>예약된 일정이 없습니다.</p>
                            <p>내 쿠폰 메뉴에서 헬스 가이드 수업을 예약하세요.</p>
                        </CardStyle> : null}
                    {
                        rows.map(
                            (n, i) => {
                                show_req_star = false;
                                show_rate_star = false;
                                state = n.state;
                                date = moment(n.class_date).format("YYYY-MM-DD")
                                day = getDay(n.class_date);
                                res_seq = n.res_seq;
                                rate_state = n.rate_state;
                                final_state = n.final_state;
                                color = '';
                                icon = '';
                                if (final_state === '예약완료') {
                                    // state = '예약완료';
                                    icon = icon_reservation;
                                }
                                if (final_state === '평가요청') {
                                    // state = '출석완료';
                                    icon = icon_class;
                                    color = 'orange';
                                }
                                if (final_state === '평가완료') {
                                    // state = '별점완료'
                                    icon = icon_star
                                    color = 'yellow';
                                }

                                // 별점요청 및 승인처리                            
                                // 수업시간 종료 x시간 내에 처리
                                class_end = n.class_date + ' ' + ('0' + n.etime).slice(-5);
                                let temp_etime = ('0' + n.etime).slice(-5, -3)
                                if (n.ampm === 'pm' && temp_etime < 12) {
                                    class_end = moment(class_end).add(12, 'hours').format("YYYY-MM-DD HH:mm");
                                }
                                star_point_end = moment(class_end).add(12, 'hours').format('YYYY-MM-DD HH:mm');

                                if (now > class_end) {
                                    if (now < star_point_end) {
                                        if (rate_state === '') {
                                            if(login.gubun==='teacher') {
                                                show_req_star = true;
                                            }
                                        }
                                        if (rate_state === '요청') {
                                            if(login.gubun==='user') {
                                                show_rate_star = true;
                                            }
                                        }
                                    }
                                    else {
                                    }
                                }
                                else {
                                }

                                return (<div key={i}>
                                    <CardStyle extra={<a href="#"></a>}>
                                        <div className={"state "+ color }>
                                            <img src={icon} />
                                            {final_state}
                                        </div>
                                        <p>{n.cname}</p>
                                        <p>{date}({day}) {n.stime}{n.ampm}</p>
                                        <p>강사명 : {n.tname} 트레이너</p>
                                        <p>회원명 : {n.uname} </p>
                                        {
                                            login.gubun === 'teacher' ? 
                                            <>
                                            <p>분류 : {n.category} </p>
                                            <p>메모 : {n.memo} </p>
                                            </> : null
                                        }
                                        

                                        {show_req_star ?
                                            <ButtonStyle
                                                onClick={() => on_rate_request_pop(n.res_seq)}
                                                type="primary"
                                                shape="round"
                                                size='large'
                                                className="req"
                                            >
                                                <img src={icon_star} />
                                                별점요청
                                            </ButtonStyle> : null}

                                        {show_rate_star ?
                                            <ButtonStyle
                                                onClick={() => on_rating_pop(n.res_seq.toString(), n.tcd, n.class_date)}
                                                type="danger"
                                                shape="round"
                                                size='large'
                                                className="rate"
                                            >
                                                <img src={icon_star} />
                                                별점주기
                                            </ButtonStyle> : null}
                                    </CardStyle>
                                </div>);
                            }
                        )}


                </>
                :
                <SpinContainer>
                    <Spin />
                </SpinContainer>
            }
        </Container>
    );
}
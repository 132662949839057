import React from 'react';
import "antd/dist/antd.css";
import styled from 'styled-components';
import SystemHgImage1 from '../../../images/system_hg_1.png';
import SystemHgImage2 from '../../../images/system_hg_2.png';
import SystemHgImage3 from '../../../images/system_hg_3.png';
const { innerWidth: width, innerHeight: height } = window;

const ScrollView = styled.div`
    height:${height - 150}px;
    overflow:auto;
    padding-bottom : 50px;
`

export default function () {
    return (
        <ScrollView>
        <div className="system system-hg">
            <div className="content-item">
                <div className="left-image">
                    <img src={SystemHgImage1} alt="시스템 헬스가이드티칭 이용 이미지" style={{width : '27.6vw'}}/>
                </div>
                <div className="right-content" style={{marginTop : '9vw'}}>
                    <h3 className="title">PT예약 시스템</h3>
                    <p>
                    <b>수업날짜, 트레이너, 시간을 앱</b>으로 손쉽게 선택하여 예약할 수 있습니다.
                    </p>
                </div>
            </div>
            <div className="content-item absolute" style={{paddingBottom : '15vw'}}>
                <div className="left-image">
                    <img src={SystemHgImage2} alt="시스템 헬스가이드티칭 이용 이미지" style={{width : '59.3vw'}}/>
                </div>
                <div className="right-content">
                    <h3 className="title">예약 확인 시스템</h3>
                    <p>
                    나의 예약 이력과 수업완료 후 평가를 진행할 수 있습니다.
                    </p>
                    <p className="warning">
                    (수업평가를 하지 않으면 출석 스탬프가 제공되지 않으니 수업후 3시간 이내에 꼭! 평가를 진행하시기 바랍니다. )
                    </p>
                </div>
            </div>
            <div className="content-item absolute">
                <div className="left-image">
                    <img src={SystemHgImage3} alt="시스템 헬스가이드티칭 이용 이미지" style={{width : '59.3vw'}}/>
                </div>
                <div className="right-content">
                    <h3 className="title">쿠폰 발급 시스템</h3>
                    <p>
                    이용권 구매시 매주 예약 가능한 <b className="weekly">WEEKLY 쿠폰</b>과 이용권 구매시 특별히 지급되는 <b className="super">SUPER 쿠폰 3장</b>이 발급됩니다.
                    </p>

                    <h5 className="weekly">WEEKLY 쿠폰</h5>
                    <p>매주 발급되는 쿠폰으로 해당 쿠폰으로 다음주 헬스 티칭가이드 예약을 할 수 있습니다.</p>

                    <h5 className="super">SUPER 쿠폰</h5>
                    <p>이용권 구매시에만 발급되는 쿠폰으로 해당 쿠폰은 발급한 다음날 부터 원하는 날짜와 시간에 예약을 할 수 있습니다.</p>
                </div>
            </div>
        </div>
        </ScrollView>
    );
}
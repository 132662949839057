import React, { useState, useEffect } from 'react';
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.css";
import {
    Layout, Menu, Button, Tabs,
    Card
} from "antd";
import {
    CloseSquareOutlined, DownloadOutlined
} from '@ant-design/icons';
import queryString from 'query-string';
import axios from 'axios';
import styled from 'styled-components';
import HgReservation from './HgReservationList';
import HgCoupon from './HgCouponList';
const { TabPane } = Tabs;
const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
  display:flex;
  flex-wrap:wrap;
  flex-direction:column;
`;

const HeaderStyle = styled.div`
  text-align:left;
  height:60px;
`

const ScrollView = styled.div`
  height:${height - 60}px;
  overflow:auto;
`

export default function () {

    const size = 'large';
    const [login, setLogin] = useState({
        mcd: '',
        gubun: '',
        app_cid: '',
        user_cid: ''
    });
    let mcd = '';
    let user_cid = '';
    let gubun = '';
    let app_cid = '';
    let token = '';
    let result;
    useEffect(() => {
        (async function () {

            // Login - localstorage
            let token = localStorage.getItem('token');
            let app_cid = localStorage.getItem('app_cid');

            // Login - params
            // const params = queryString.parse(window.location.search);          
            // if(token==='') {
            //   token = params.token;
            //   app_cid = params.cid;
            // }

            result = await axios.post('https://api.smartg.kr:3000/sp/get_login', {
                token: token
            });
            if (result.data.mcd !== undefined) { mcd = result.data.mcd; }
            if (result.data.cid !== undefined) { user_cid = result.data.cid; }
            if (result.data.gubun !== undefined) { gubun = result.data.gubun; }

            if (mcd !== '') {
                setLogin({
                    mcd: mcd,
                    user_cid: user_cid,
                    app_cid: app_cid,
                    gubun: gubun
                });
            } else {
                alert('로그인 상태가 아닙니다.');
                close_webview_window();
            }
        })();
    }, []);

    const close_webview_window = () => {
        if (window.ReactNativeWebView !== undefined) {
            window.ReactNativeWebView.postMessage('close_webview_window');
        }
    }

    const close_click = () => {
        if (window.ReactNativeWebView === undefined) {
            alert('앱용 기능입니다.');
            return;
        }
        close_webview_window();
    }

    const back_click = () => {
        window.history.back();
    }

    const complete_click = () => {
        alert('쿠폰발급 완료');
    }

    return (
        <>
            { login.mcd === '' ?
                <div>
                </div>
                :
                <Container>

                    <HeaderStyle>
                        <Button type="primary" shape="round" icon={<CloseSquareOutlined />} size={size} onClick={() => close_click()}>CLOSE</Button>
            &nbsp;<Button type="primary" shape="round" icon={<CloseSquareOutlined />} size={size} onClick={() => back_click()}>BACK</Button>
                    </HeaderStyle>

                    <ScrollView>
                        <Button type="primary" shape="round" size={size} onClick={() => complete_click()}>
                            구매완료 쿠폰발급
                        </Button>
                    </ScrollView>

                </Container>}
        </>
    );
}
import React from 'react';
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Form, Input, Button, Checkbox, Layout, Menu, useHistory } from "antd";
import "antd/dist/antd.css";
import styled from 'styled-components';
import store from '../store';
import axios from 'axios';

const Container = styled(Layout)`  
  align-items:center;
  height:100vh;  
`;

const FormContainer = styled(Layout)`    
  justify-content:center;
  min-width:350px;
`;

const { Header, Sider, Content, Footer } = Layout;

export default function ({ location }) {

  const { from } = location.state || { from: { pathname: "/" } };
  // console.log(from);
  const [ form ] = Form.useForm();

  const onFinish = values => {
    // console.log(values.username);
    request_refresh_token();
  }

  async function request_refresh_token () {

    const host = process.env.NODE_ENV==='production'? store.production:store.development;
    let result;

    try {
      result = await axios.get(host+'/sp/req_refresh_token'); // ~5분
      const refresh_token = result.data.refresh_token;
      window.localStorage.setItem('refresh_token', refresh_token);

      result = await axios.get(host+'/sp/req_access_token'); // ~1달
      const access_token = result.data.access_token;
      window.localStorage.setItem('access_token', access_token);

      window.location.replace(from.pathname);

    } catch ( e ) {
      alert('인터넷 연결 오류입니다.');
    }

  }

  return (
    <Container>
      <FormContainer>
        
        <Form 
        labelCol={{ span:8 }}
        wrapperCol={{ span:16 }}
        form={form}
        onFinish={onFinish}
        >
          <Form.Item labelAlign="center"
          wrapperCol={{ offset:8 }}
          >
            관리자 로그인
          </Form.Item>
        
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset:8, span:16}}>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item  wrapperCol={{ offset:8, span:16}}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </FormContainer>
    </Container>
  );
}
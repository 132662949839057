import React, { useState, useEffect } from 'react';
import "antd/dist/antd.css";
import axios from 'axios';
import styled from 'styled-components';
import store from '../../store';
const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;
    color : #fff;
`;

const ScrollView = styled.div`
    height:${height - 150}px;
    overflow:auto;
    padding-bottom : 50px;
`

const FaqContainer = styled.div`
    width : 80%;
    margin : 0 auto;
    padding-top : 8.75vw;

`

const FaqItem = styled.div`
    padding : 10px 0;
    margin-bottom : 9vw;
    word-break : keep-all;

    .question {
        font-weight : 700;
    }

    .answer{
        margin : 0;
    }

`


export default function () { 
    const [data, setData] = useState([]);

    useEffect(() => {
        (async function () {
            let api_host = store.production.api_host;
            if( process.env.REACT_APP_ENV === 'dev' ) api_host = store.development.api_host;
            if( process.env.REACT_APP_ENV === 'staging' ) api_host = store.staging.api_host;

            // FAQ 정보 가져오기
            const result = await axios.post(api_host + '/center/get_faq', {
            });
            setData(result.data);
        })();
    }, []);

    return (
        <>
        <Container>
            <ScrollView>
            <FaqContainer>
                {
                    data.map((item, index) => {
                        return (
                            <FaqItem key={index}>
                                <p className="question">
                                    {item.question}
                                </p>
                                {
                                    item.answer ?
                                    item.answer.map((i, idx) => {
                                        return(
                                            <p className="answer" key={idx}>
                                                {i}
                                            </p>
                                        )
                                    })
                                    : null
                                }
                            </FaqItem>
                        )
                    })
                }
            </FaqContainer>
            </ScrollView>
        </Container>
        </>
    );
}
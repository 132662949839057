import React, { useState, useEffect } from 'react';
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.css";
import {
  Layout, Menu, Button, Tabs,
  Card
} from "antd";
import {
  CloseSquareOutlined, DownloadOutlined, CloseOutlined
} from '@ant-design/icons';
import queryString from 'query-string';
import axios from 'axios';
import styled from 'styled-components';
import HgReservation from './HgReservationList';
import HgCoupon from './HgCouponList';
import HgFrequency from './HgFrequency';
import { pr } from './pr';
const { TabPane } = Tabs;
const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
  display:flex;
  flex-wrap:wrap;
  flex-direction:column;
  color : #fff;
`;

const HeaderStyle = styled.div`
  text-align:center;
  height:65px;
  line-height : 65px;
  border-bottom : 1px solid #f9f9f9;

  & .close-icon {
    position : absolute;
    top : 20px;
    left : 20px;
    font-size : 22px;
  }
`

const ScrollView = styled.div`
  height:${height - 60}px;
  overflow:auto;
`

const TabCotainerStyle = styled.div`
  & .ant-tabs-nav-list {
    width : 100%;
  }
  & .ant-tabs-tab {
    justify-content : center;
    width : 50%;
    margin : 0;
  }
  & .ant-tabs-ink-bar{
    display : none;
  }
  & .ant-tabs-tab-btn{
    color: #fff;
  }
  & .ant-tabs-tab.ant-tabs-tab-active{
    background-color: #15ff94 !important;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color : #111 !important;
  }
  
  & .ant-tabs-nav-list{
    border-bottom : 8px solid #212121;
  }
  
  & .ant-card{
    background-color : transparent !important;
    color : #fff;
    width : 90%;
    margin : 10px auto;
  }
  & .ant-card-head{
    color : #fff;
  }
  & .ant-card.super .ant-card-head{
    background-color : #f49f11;
  }
  & .ant-card.weekly .ant-card-head{
    background-color : #956bc1;
  }
  & .ant-card-body{
    /* padding : 5px !important; */
  }
`

export default function () {

  const size = 'large';
  const [login, setLogin] = useState({
    mcd: '',
    gubun: '',
    cid: '',
    user_cid: ''
  });
  let mcd = '';
  let user_cid = '';
  let gubun = '';
  let cid = '';
  let token = '';
  let result;
  const [tab, setTab] = useState('1');

  useEffect(() => { // Webview login
    (async function () {

      //Login
      const params = queryString.parse(window.location.search);
      token = localStorage.getItem('token');
      cid = localStorage.getItem('cid');      
      if (params.token !== undefined || params.cid !== undefined) {
        token = params.token;
        cid = params.cid;
      }

      if (token) {
        result = await axios.post('https://api.smartg.kr:3000/sp/get_login', {
          cid:cid,
          token: token
        });
        if (result.data.mcd !== undefined) { mcd = result.data.mcd; }
        if (result.data.gubun !== undefined) { gubun = result.data.gubun; }
        if (mcd !== '') {
          setLogin({
            mcd: mcd,
            cid: cid,
            gubun: gubun
          });
          localStorage.setItem('token', token); // Save token
          localStorage.setItem('cid', cid);
        }
      }     
    })();
  }, []);

  useEffect(() => { // Handle tab
    const params = queryString.parse(window.location.search);
    if (params.tab !== undefined) {
      setTab(params.tab);
    }
  }, []);

  const close_webview_window = () => {
    if (window.ReactNativeWebView !== undefined) {
      window.ReactNativeWebView.postMessage('close_webview_window');
    }
  }

  const close_click = () => {
    if (window.ReactNativeWebView === undefined) {
      alert('앱용 기능입니다.');
      return;
    }
    close_webview_window();
  }

  const params = queryString.parse(window.location.search);
  const isFrequency = params.isFrequency !== undefined ?  params.isFrequency : '';
  const isRate = params.isRate !== undefined ?  params.isRate : '';

  return (
    <>
      { login.mcd === '' ?
        <div>
        </div>
        :
        <Container>

        { isFrequency === "Y" ? <HgFrequency token={token} login={login} isRate={isRate} />
        : <>
          <HeaderStyle>
            {/* <Button type="primary" shape="round" icon={<CloseSquareOutlined />} size={size} onClick={() => close_click()}></Button> */}
            <CloseOutlined onClick={() => close_click()} className="close-icon"/>
            헬스가이드티칭
          </HeaderStyle>

          <ScrollView>
            <TabCotainerStyle>
            <Tabs defaultActiveKey={tab} >
              <TabPane tab="내 예약" key="1">
                <HgReservation token={token} login={login} />
              </TabPane>
              <TabPane tab="내 쿠폰" key="2">
                <HgCoupon token={token} login={login} />
              </TabPane>
            </Tabs>     
            </TabCotainerStyle>       
          </ScrollView>
          </>
        }
        </Container>}
    </>
  );
}


{/* <hr />
            <div>
              <ul>
                <li>cid:{login.cid}</li>
                <li>mcd:{login.mcd}</li>
                <li>gubun:{login.gubun}</li>
              </ul>
            </div>
             */}
import React, { useState, useEffect } from 'react';
import "antd/dist/antd.css";
import axios from 'axios';
import styled from 'styled-components';
import store from '../../store';
import NumberFormat from 'react-number-format';

const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;
    color : #fff;
`;

const ScrollView = styled.div`
    height:${height - 150}px;
    overflow:auto;
`

const PriceContainer = styled.div`
    width : 80%;
    margin : 0 auto;
    padding-top : 8.75vw;
    color : #fff;

    & table{
        width : 100%;
        text-align : center;
        border : 1px solid #fff;
    }

    & th{
        background-color : #f31b44;
        border : 1px solid #fff;
        font-size : 2.8vw;
    }

    & tr{
        height : 8.59vw;
    }

    & td{
        border : 1px solid #fff;
        font-size : 3vw;
        /* font-size : 12px; */
    }
`


export default function (props) { 
    const [data, setData] = useState([]);
    let cid = props.cid;


    useEffect(() => {
        (async function () {
            let api_host = store.production.api_host;
            if( process.env.REACT_APP_ENV === 'dev' ) api_host = store.development.api_host;
            if( process.env.REACT_APP_ENV === 'staging' ) api_host = store.staging.api_host;

            // Coupon list
            const result = await axios.post(api_host + '/center/get_price', {
                cid: cid
            });
            console.log(result.data.rows);
            setData(result.data.rows);
            // setLoaded(true);
        })();
    }, []);


    return (
        <>
        <Container>
            <ScrollView>
            <PriceContainer>
                <table>
                    <tr>
                        <th>상품명</th>
                        <th>가격</th>
                    </tr>
                {
                    data.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.item_name + " " + item.detail}</td>
                                <td><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} suffix={'원'} /></td>
                            </tr>
                        )
                    })
                }
                </table>
            </PriceContainer>
            </ScrollView>
        </Container>
        </>
    );
}
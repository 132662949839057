import React from "react";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Hello from './Hello';
import HgHome from './HgHome';
import HgReservation from './HgReservation';
import HgFrequency from './HgFrequency';
import HgFrequencyInfo from './HgFrequencyInfo';
import HgTest from './HgTest';
import Center from './Center';
import Test from './Test';

export default function ({ authenticated, render }) {
  return (
    <>      
      <Route path="/hello" render={props => <Hello {...props} />} />
      <Route path="/hg_home" render={props => <HgHome {...props} />} />
      <Route path="/hg_reservation" render={props => <HgReservation {...props} />} />
      <Route path="/hg_frequency" render={props => <HgFrequency {...props} />} />
      <Route path="/hg_frequency_info" render={props => <HgFrequencyInfo {...props} />} />
      <Route path="/center" render={props => <Center {...props} />} />
      <Route path="/test" render={props => <Test {...props} />} />
      <Route path="/hg_test" render={props => <HgTest {...props} />} />
    </>    
  );
}
import React, { useState, useEffect } from 'react';
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.css";
import {
    Layout, Menu, Button, Tabs,
    Card
} from "antd";
import {
    CloseOutlined, LeftOutlined
} from '@ant-design/icons';
import queryString from 'query-string';
import axios from 'axios';
import styled from 'styled-components';
import Facility from './Center/Facility';
import Trainer from './Center/Trainer';
import Price from './Center/Price';
import System from './Center/System';
import FAQ from './Center/FAQ';
const { TabPane } = Tabs;
const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`

`;

const HeaderStyle = styled.div`
  text-align:center;
  height:16.25vw;
  line-height : 16.25vw;
  border-bottom : 1px solid #454545;
  color : #fff;
  font-size : 4vw;
  font-weight : 700;

  & .close-icon {
    position : absolute;
    top : 20px;
    left : 20px;
    font-size : 22px;
  }
`

const TabCotainerStyle = styled.div`
  margin-bottom : 50px;

  & .ant-tabs-nav{
    margin : 0;
  }

  & .ant-tabs-nav-list {
    width : 100%;
  }
  & .ant-tabs-tab {
    justify-content : center;
    width : 20%;
    margin : 0;
    font-size : 3vw;
    line-height : 1.1;
    text-align : center;
    padding : 5px 3px;
    font-weight : 700;
  }
  & .ant-tabs-ink-bar{
    display : none;
  }
  & .ant-tabs-tab-btn{
    color: #fff;
    white-space : pre-wrap;
    word-break : keep-all;
  }
  & .ant-tabs-tab.ant-tabs-tab-active{
    background-color: #f31b44 !important;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color : #fff !important;
    font-weight : 700;
  }
  
  & .ant-tabs-nav-list{
    /* border-bottom : 8px solid #212121; */
    border-bottom : 1px solid #454545;
  }
  
  & .ant-card{
    background-color : transparent !important;
    color : #fff;
    width : 90%;
    margin : 10px auto;
  }
  & .ant-card-head{
    color : #fff;
  }
  & .ant-card.super .ant-card-head{
    background-color : #f49f11;
  }
  & .ant-card.weekly .ant-card-head{
    background-color : #956bc1;
  }
  & .ant-card-body{
    /* padding : 5px !important; */
  }
`

const LoginButton = styled.button`
  position : absolute;
  bottom : 0;
  left : 0;
  width : 100%;
  height : 50px;
  font-size : 18px;
  color : #fff;
  background-color : #f31b44;
  border : none;
`

export default function () {

    const [cid, setCid] = useState('');
    const params = queryString.parse(window.location.search);
    const isLogged = params.isLogged !== undefined ?  params.isLogged : '';
    const close_webview_window = () => {
        if (window.ReactNativeWebView !== undefined) {
            window.ReactNativeWebView.postMessage('close_webview_window');
        }
    }

    const go_login = () => {
      if (window.ReactNativeWebView !== undefined) {
          window.ReactNativeWebView.postMessage('go_login');
      }
  }

    const close_click = () => {
        if (window.ReactNativeWebView === undefined) {
            alert('앱용 기능입니다.');
            return;
        }
        close_webview_window();
    }

    const login_click = () => {
      if (window.ReactNativeWebView === undefined) {
          alert('앱용 기능입니다.');
          return;
      }
      go_login();
  }

    useEffect(() => {
          const params = queryString.parse(window.location.search);
          if (params.cid !== undefined) {
            setCid(params.cid);
          }
      }, []);

    return (
        <>
        <Container>
            <HeaderStyle>
                <LeftOutlined onClick={() => close_click()} className="close-icon"/>센터소개
            </HeaderStyle>
            <TabCotainerStyle>
            <Tabs defaultActiveKey="1" swipeable={true}>
                <TabPane tab="시설안내" key="1">
                  <Facility cid={cid}/>
                </TabPane>
                <TabPane tab="강사소개" key="2">
                  <Trainer cid={cid}/>
                </TabPane>
                <TabPane tab="이용금액" key="3">
                  <Price cid={cid}/> 
                </TabPane>
                {/* <TabPane tab={`두써킷\n그룹PT 안내`} key="4">
                  Content of Tab Pane 3
                </TabPane> */}
                <TabPane tab={`이용안내`} key="5">
                  <System cid={cid}/>
                </TabPane>
                <TabPane tab={`FAQ`} key="6">
                  <FAQ />
                </TabPane>
            </Tabs>
            </TabCotainerStyle>
            {
              isLogged === 'Y' ? <></>
              : <LoginButton onClick={() => login_click()}>로그인 바로가기</LoginButton>
            }
        </Container>
        </>
    );
}
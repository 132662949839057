import React from 'react';
import "antd/dist/antd.css";
import styled from 'styled-components';
import SystemAppImage1 from '../../../images/system_app_1.png';
import SystemAppImage1_p from '../../../images/system_app_1_p.png';
import SystemAppImage2 from '../../../images/system_app_2.png';
import SystemAppImage3 from '../../../images/system_app_3.png';
const { innerWidth: width, innerHeight: height } = window;

const ScrollView = styled.div`
    height:${height - 150}px;
    overflow:auto;
    padding-bottom : 50px;
`

export default function (props) {
    let cid = props.cid;
    
    return (
        <ScrollView>
        <div className="system system-app">
            <div className="content-item">
                <div className="left-image">
                    {
                        cid === 'CT_GYM_00000010' ? 
                        <img src={SystemAppImage1_p} alt="시스템 어플이용 이미지" style={{width : '36.5vw'}}/>
                        : <img src={SystemAppImage1} alt="시스템 어플이용 이미지" style={{width : '36.5vw'}}/>
                    }
                </div>
                <div className="right-content">
                    <h3 className="title">자동 출입시스템</h3>
                    <p>
                    이용권 구매를 통해 받은 스마트키를 해당 센터의 출입문으로 가까이 다가가면 <b>문열기 버튼</b>이 활성화 됩니다. 이때 버튼을 클릭하면 출입문이 자동으로 열립니다.
                    </p>
                </div>
            </div>
            {/* <div className="content-item">
                <div className="left-image">
                    <img src={SystemAppImage2} alt="시스템 어플이용 이미지" style={{width : '27.6vw'}}/>
                </div>
                <div className="right-content">
                    <h3 className="title">알림 시스템</h3>
                    <p>
                    이용권 및 헬스가이드 티칭 관련 푸쉬알림으로 나에게 필요한 정보를 알려줍니다.
                    </p>
                </div>
            </div> */}
            <div className="content-item" >
                <div className="left-image">
                    <img src={SystemAppImage3} alt="시스템 어플이용 이미지" style={{width : '40vw'}}/>
                </div>
                <div className="right-content" style={{marginTop : '22vw'}}>
                    <h3 className="title">결제 시스템</h3>
                    <p>
                    센터에 방문하지 않고 24시간 언제든 편리하게 앱에서 이용권을 구매할 수 있습니다.
                    </p>
                </div>
            </div>
        </div>
        </ScrollView>
    );
}
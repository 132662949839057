import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import store from '../../store';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/swiper-bundle.css';
const { innerWidth: width, innerHeight: height } = window;

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const Container = styled.div`
    /* display:flex;
    flex-wrap:wrap;
    flex-direction:column; */
    color : #fff;
`;

const ScrollView = styled.div`
    height:${height - 150}px;
    padding-bottom : 50px;
    overflow:auto;
`

const FacilityContainer = styled.div`
    width : 80%;
    margin : 0 auto;
    padding-top : 8.75vw;

    & .slide-image{
        width : 100%;
        height : auto;
        margin-bottom : 30px;
    }

    & .slide-title{
        position : absolute;
        color : #fff;
        bottom : 30px;
        width : 100%;
        text-align : center;
        background-color : #11111180
        /* margin-top : -120px; */
    }

    & .swiper-pagination-bullet{
        background: #fff;
    }

    & .swiper-pagination-bullet-active{
        background: #f31b44;
    }

    & .swiper-pagination{
        bottom : 0;
    }

    & .swiper-button-prev, .swiper-button-next{
        color : #ffffff;
    }

`

const CenterInfoContainer = styled.div`
    margin-top : 5vw;

    & h3{
        color : #ffffff;
        margin-bottom : 0;
    }

    & b{
        color : #15ff94;
    }

    & p{
        color : #ffffff;
        text-align : justify;
        /* width : 30px; */
        text-justify: inter-word
    }

    & table{
        font-size : 3vw;
        margin-bottom : 3vw;
    }

    & table .title{
        width : 12.5vw;
        text-align :justify;
        word-break : break-all;
    }

    & .map-image{
        width : 100%;
    }

`


export default function (props) { 
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);

    let cid = props.cid;

    async function getData() {
        let api_host = store.api_host;

        // 시설안내 데이터
        const result = await axios.post(api_host + '/center/get_facility', {
            cid: cid
        });
        if(result.data){
            setData(result.data);
            setLoaded(true);
        }
    };

    useEffect(() => {
        if(cid !== ''){
            getData();
        }
    }, [cid]);

    return (
        <>
        {
            loaded ?
            <Container>
                <ScrollView>
                <FacilityContainer>
                    {
                        data.slider?
                        <Swiper
                            // spaceBetween={50}
                            slidesPerView={1}
                            navigation ={{ clickable : true }}
                            pagination={{ clickable: true }}
                            // onSlideChange={() => console.log('slide change')}
                            // onSwiper={(swiper) => console.log(swiper)}
                        >
                            {
                                data.slider.map((item, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <img src={"/assets/images" + item.image} alt="시설 사진" className="slide-image"/>
                                            <div className="slide-title">{item.title}</div>
                                        </SwiperSlide>
                                    )
                                })
                                
                            }
                        </Swiper>
                        : <></>
                    }
                    
                    <CenterInfoContainer>
                        <h3>{data.center_name + " "}<b>{data.location}</b></h3>
                        {/* <p>주   소 : {centerInfo.address}</p>
                        <p>운영시간 : {centerInfo.operating_time}</p>
                        <p>연 릭 차: {centerInfo.number}</p> */}
                        <table>
                            <tr>
                                <td className="title">주 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소</td>
                                <td>:</td>
                                <td>{data.address}</td>
                            </tr>
                            <tr>
                                <td className="title">운영시간</td>
                                <td>:</td>
                                <td>{data.operating_time}</td>
                            </tr>
                            <tr>
                                <td className="title">연 &nbsp;락&nbsp; 처</td>
                                <td>:</td>
                                <td>{data.number}</td>
                            </tr>
                        </table>
                        <img src={"/assets/images/" + cid+"/map.jpg"} alt={"/assets/images/" + cid+"/map.jpg"} className="map-image"/>
                        {/* <iframe src={data.api} width={width*0.8} height="200" loading="lazy" allowFullScreen={false}></iframe> */}
                        {/* <iframe src="https://place.map.kakao.com/966777407" width={width*0.8} height="200" loading="lazy" allowFullScreen={false}></iframe> */}
                    </CenterInfoContainer>
                    <div style={{marginTop:30}}></div>
                    
                </FacilityContainer>
                </ScrollView>
            </Container>
            : <></>
        }
        </>
    );
}
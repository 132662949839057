import React from "react";
import { useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Home from "./components/Home";
import Member from './components/Member';
import Login from './components/Login';
import MyRoute from './components/MyRoute';

function signIn() {
  return { name: "Kim" };
}

function App() {
  const [user, setUser] = useState(null);
  const authenticated = user != null; // true or false

  return (
    <Router>
      <Switch>
        <Switch>
          <MyRoute/>        
        </Switch>
      </Switch>
    </Router>
  );
}

export default App;


{/* <AuthRoute
path="/member"
authenticated={authenticated}
render={(props) => <Member {...props} />}
/>
<Route exact path="/" render={(props) => <Home {...props} />} />                
<Route path="/login" render={(props) => <Login {...props} />} />     */}
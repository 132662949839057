import React, { useState, useEffect } from 'react';
import "antd/dist/antd.css";
import axios from 'axios';
import styled from 'styled-components';
import store from '../../store';
const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
    color : #fff;
`;

const ScrollView = styled.div`
    height:${height - 150}px;
    overflow:auto;
`

const TrainerContainer = styled.div`
    width : 80%;
    margin : 0 auto;
    padding-top : 8.75vw;

`

const TrainerItem = styled.div`
    display : flex;
    border-bottom : 1px solid #454545;
    padding-bottom : 9vw;
    margin-bottom : 9vw;

    :last-child{
        border-bottom : none;
    }

    .left-image img{
        width : 27.6vw;
    }

    .right-content {
        padding-left : 6.24vw;
    }

    .right-content .name{
        font-size : 4vw;
        font-weight : 700;
        margin-bottom : 2vw;
    }

    .right-content p{
        margin : 0;
        font-size : 2.5vw;
        color : #bcbcbc;
    }

    .right-content .section{
        margin-bottom : 3.12vw;
    }

`


export default function (props) { 
    const [data, setData] = useState([]);
    let cid = props.cid;

    useEffect(() => {
        (async function () {
            let api_host = store.production.api_host;
            if( process.env.REACT_APP_ENV === 'dev' ) api_host = store.development.api_host;
            if( process.env.REACT_APP_ENV === 'staging' ) api_host = store.staging.api_host;
            // trainer 정보 가져오기
            const result = await axios.post(api_host + '/center/get_trainer', {
                cid: cid
            });
            setData(result.data);
        })();
    }, []);

    return (
        <>
        <Container>
            <ScrollView>
            <TrainerContainer>
                {
                    data.map((item, index) => {
                        return (
                            <TrainerItem key={index}>
                                <div className="left-image">
                                    <img src={"/assets/images" + item.image} alt="강사 사진" />
                                </div>
                                <div className="right-content">
                                    <div className="name">{item.name}</div>
                                    <div className="section">
                                    <p>학력</p>
                                    {
                                        item.education.map((i, idx) => {
                                            return(
                                                <p key={idx}>{i}</p>
                                            )
                                        })
                                    }
                                    </div>
                                    <div className="section">
                                    <p>경력</p>
                                    {
                                        item.career.map((i, idx) => {
                                            return(
                                                <p key={idx}>{i}</p>
                                            )
                                        })
                                    }
                                    </div>
                                    <div className="section">
                                    <p>자격</p>
                                    {
                                        item.qulification.map((i, idx) => {
                                            return(
                                                <p key={idx}>{i}</p>
                                            )
                                        })
                                    }
                                    </div>

                                </div>
                            </TrainerItem>
                        )
                    })
                }
            </TrainerContainer>
            </ScrollView>
        </Container>
        </>
    );
}
import React, { useState, useRef } from 'react';
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.css";
import {
  Layout, Menu, Button, Tabs,
  Card
} from "antd";
import { render } from '@testing-library/react';

export default function () {

  const list = [...Array(10).keys()];
  console.log(list);

  const inputRef = useRef([]);

  return (
    <div>
      <a href="http://127.0.0.1:8080/center?cid=CT2019041700000001">삼화점</a>
    </div>
  );

  // return (
  //   <div>
  //     {list.map(x => (
  //       <div style={{ marginBottom:5}}>
  //         <input
  //           key={x}
  //           ref={el => inputRef.current[x] = el}
  //           type="text"
  //         />          
  //         <Button type="primary" onClick={()=>inputRef.current[x].value='inputRef'+x+'클릭함'}>클릭</Button>
  //       </div>
  //     ))}
  //   </div>
  // );
}
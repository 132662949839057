import React, { useState, useEffect } from 'react';
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.css";
import {
    Layout, Menu, Button, Tabs,
    Card
} from "antd";
import {
    CloseOutlined, LeftOutlined
} from '@ant-design/icons';
import queryString from 'query-string';
import axios from 'axios';
import styled from 'styled-components';
import Trainer from './Center/Trainer';
import Price from './Center/Price';
import System from './Center/System';
import Facility from './Center/Facility';
const { TabPane } = Tabs;
const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
  /* display:flex;
  flex-wrap:wrap;
  flex-direction:column; */
`;

const HeaderStyle = styled.div`
  text-align:center;
  height:16.25vw;
  line-height : 16.25vw;
  border-bottom : 1px solid #454545;
  color : #fff;
  font-size : 4vw;
  font-weight : 700;

  & .close-icon {
    position : absolute;
    top : 20px;
    left : 20px;
    font-size : 22px;
  }
`

const ScrollView = styled.div`
  height:${height - 60}px;
  overflow:auto;
`

const TabContainer = styled.div`
    /* background-color : #fff; */
    color : #fff;

    & .ant-tabs-tab-btn{
        color : #fff !important;
    }

    & .ant-tabs-tab-active{
        background-color : red;
    }

    & .ant-tabs-top > .ant-tabs-nav::before{
        border-bottom : 1px solid #454545;
    }

`

const TabCotainerStyle = styled.div`
  & .ant-tabs-nav{
    margin : 0;
  }

  & .ant-tabs-nav-list {
    width : 100%;
  }
  & .ant-tabs-tab {
    justify-content : center;
    width : 20%;
    margin : 0;
    font-size : 3vw;
    line-height : 1.1;
    text-align : center;
    padding : 5px 3px;
    font-weight : 700;
  }
  & .ant-tabs-ink-bar{
    display : none;
  }
  & .ant-tabs-tab-btn{
    color: #fff;
    white-space : pre-wrap;
    word-break : keep-all;
  }
  & .ant-tabs-tab.ant-tabs-tab-active{
    background-color: #f31b44 !important;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color : #fff !important;
    font-weight : 700;
  }
  
  & .ant-tabs-nav-list{
    /* border-bottom : 8px solid #212121; */
    border-bottom : 1px solid #454545;
  }
  
  & .ant-card{
    background-color : transparent !important;
    color : #fff;
    width : 90%;
    margin : 10px auto;
  }
  & .ant-card-head{
    color : #fff;
  }
  & .ant-card.super .ant-card-head{
    background-color : #f49f11;
  }
  & .ant-card.weekly .ant-card-head{
    background-color : #956bc1;
  }
  & .ant-card-body{
    /* padding : 5px !important; */
  }
`

export default function () {

    const size = 'large';
    let cid = '';

    const close_webview_window = () => {
        if (window.ReactNativeWebView !== undefined) {
            window.ReactNativeWebView.postMessage('close_webview_window');
        }
    }

    const close_click = () => {
        if (window.ReactNativeWebView === undefined) {
            alert('앱용 기능입니다.');
            return;
        }
        close_webview_window();
    }

    const back_click = () => {
        window.history.back();
    }

    const complete_click = () => {
        alert('쿠폰발급 완료');
    }

    const callback = (key) => {
        console.log(key);
    }

    useEffect(() => {
          const params = queryString.parse(window.location.search);
          cid = localStorage.getItem('cid');      
          if (params.cid !== undefined) {
            cid = params.cid;
          }
          localStorage.setItem('cid', cid);
      }, []);

    return (
        <div style={{color:"white", fontSize:20, padding: 10}}>
          스마트짐 앱
          <div>
            <a href="http://127.0.0.1:8080/center?cid=CT2019041700000001 ">센터소개</a>
          </div>
        </div>
       
    );
}
import React,{ useEffect, useState } from 'react';
import store from '../store';
import {
    Layout, Menu, Button, Tabs,
    Card,Tag
} from "antd";
import styled from 'styled-components';
import axios from 'axios';
import {
    CloseSquareOutlined, DownloadOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { getMonday } from './getMonday';
import { getNextFriday } from './getNextFriday';
import { pr } from './pr';
import coupon_super from '../images/coupon_super.png';
import coupon_weekly from '../images/coupon_weekly.png';
import coupon_no from '../images/coupon_no.png';

const Container = styled.div`
    width : 90%;
    max-width : 310px;
    margin : 20px auto;
`
const CouponStyle = styled.div`
    color : #fff;
    background-image : url(${props => props.image});
    background-size : 100% auto;
    background-repeat : no-repeat;
    display : flex;
    flex : 1;
    margin-bottom : 10px;
    padding-bottom : 10px;
    
    &.no {
        height : 85px;
    }
    
`
const CouponLeft = styled.div`
    flex : 3.8;
    justify-content : center;
    align-items : center;
    text-align : center;
    display : flex;
    flex-direction : column;


    & .en{
        font-size : 12px;
        font-weight : 800;
    }

    & .ko{
        font-size : 11px;
    }

    & .coupon{
        margin-top : -8px;
        margin-bottom : -5px;
    }

    &.no-left{
        flex : 3.2;
        line-height : 1.2;
    }

`

const CouponRight = styled.div`
    flex : 8;
    font-size : 12px;
    padding : 20px 0px 20px 5px;

    & .highlight{
        font-weight : 800;
    }

    & .time{
        margin-top : -5px;
        margin-bottom : -5px;
    }

    &.no-right{
        display : flex;
        flex-direction : column;
        justify-content :center;
    }
`

const CouponButton = styled.div`
    flex : 0.5;
    display : flex;
    align-items : center;
    padding-right : 8px;
`

const ButtonStyle = styled(Button)`
    font-size : 11px;
    padding : 0px 2px;
    height : 20px;
    line-height : 20px;
    background-color : #4c6eec;
`

export default function (props) {

    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        (async function () {
            const { mcd, cid } = props.login;
            let api_host = store.production.api_host;
            if( process.env.REACT_APP_ENV === 'dev' ) api_host = store.development.api_host;
            if( process.env.REACT_APP_ENV === 'staging' ) api_host = store.staging.api_host;

            // Coupon list
            const result = await axios.post(api_host + '/hg/get_coupon', {
                mcd: mcd,
                cid: cid
            });
            setData(result.data.rows);
            setLoaded(true);
        })();
    }, []);

    const go_reservation = (seq,sdate,edate) => {
        window.location.href = '/hg_reservation?seq='+seq+'&sdate='+sdate+'&edate='+edate;
    }

    // 담주 금요일
    const today = moment().format('YYYY-MM-DD');
    const monday = getMonday(today);
    const nextFriday = getNextFriday(monday);

    return (
        <Container>

            { loaded === true ?
                <>
                    { data.length === 0 ?
                        <CouponStyle image={coupon_no} className="no" extra={<a href="#"></a>}>
                            <CouponLeft className="no-left">
                                <div className="en">NO</div>
                                <div className="en">COUPON</div>
                            </CouponLeft>
                            
                            <CouponRight className="no-right">
                                <div className="highlight">헬스가이드 티칭 쿠폰이 없습니다.</div>
                                <div>이용권 구매 시 쿠폰이 발급됩니다.</div>
                            </CouponRight>
                            <CouponButton>
                            </CouponButton>
                        </CouponStyle>
                        :
                        <>
                            {data.map((i, k) => {
                                const { type, class_sdate, class_edate, cname, seq } = i;
                                let sdate = moment(class_sdate).format('YYYY-MM-DD');  
                                let edate = moment(class_edate).format('YYYY-MM-DD');   
                                
                                // 쿠폰필터링
                                
                                // 슈퍼쿠폰은 내일부터~
                                if (type === 'super') {                                    
                                    if(today < sdate) {
                                        sdate = today;
                                    }                 
                                    if(today >= sdate)
                                    {                                        
                                        sdate = moment(today).add(1, 'day').format('YYYY-MM-DD');
                                    }                                                   
                                }            
                                
                                // 담주 금요일까지 예약가능
                                if(edate > nextFriday) {
                                    edate = nextFriday;
                                }                    
                                let en = '';
                                let ko = '';
                                let image = '';
                                if(type === 'super'){
                                    en = 'SUPER';
                                    ko = '슈퍼 쿠폰';
                                    image = coupon_super;
                                }else{
                                    en = 'WEEKLY';
                                    ko = '위클리 쿠폰';
                                    image = coupon_weekly;
                                }

                                return (
                                    <CouponStyle key={k} image={image} extra={<a href="#"></a>} className={type}>
                                        <CouponLeft>
                                            <div className="en">{en}</div>
                                            <div className="en coupon">COUPON</div>
                                            <div className="ko">{ko}</div>
                                        </CouponLeft>
                                        
                                        <CouponRight>
                                            <div className="highlight">이용지점 : {cname}</div>
                                            <div className="time">예약가능한 시간</div>
                                            <div>{sdate} ~ {edate}</div>
                                        </CouponRight>
                                        <CouponButton>
                                        <ButtonStyle type="primary" size='large' onClick={() => go_reservation(seq,sdate,edate)}>
                                            예약하기
                                        </ButtonStyle >
                                        </CouponButton>
                                    </CouponStyle>
                                );
                            })}

                        </>
                    }
                </>
                : <></>
            }
        </Container>
    );
}